import React, { useRef, useState, useLayoutEffect } from 'react'
import { MRT_Cell } from 'mantine-react-table'
import { Box, Stack } from '@mantine/core'
import StackedBar from './StackedBar'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, LabelList, ResponsiveContainer } from 'recharts';
import { toPercentage } from '../../utils';

interface BarData {
    name: string;
    value: number;
    color: string;
}

interface BarChartHeaderProp {
    data: BarData[];
    height: number;
    title?: string;
}

export const BarChartHeader: React.FC<BarChartHeaderProp> = ({ data, height = 50, title = "Detailed Score" }) => {
    return (
        <Stack style={{ width: "200px"  }}>
            <div style={{ textAlign: 'center', fontWeight: 'bold', height: "20%"}}>{title}</div>
            <div style={{ height: "80%"}}>
                <ResponsiveContainer height="100%">
                    <BarChart data={data} barGap={0} barCategoryGap="0%" title={title}>
                        <XAxis dataKey="name" hide />
                        <YAxis hide padding={{ top: 10}}  />
                        <CartesianGrid vertical={false} horizontal={false} />
                        <Bar dataKey="value">
                            {
                                data.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.color} />
                                ))
                            }
                            <LabelList dataKey="value" position="inside" fontSize={12} formatter={toPercentage} fill='white'/>
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </Stack>
    );
}

interface StackedBarCellProps {
    cell: MRT_Cell<BarData[]>
    showValues?: boolean
    domain: [number, number]
}

export const StackedBarCell: React.FC<StackedBarCellProps> = ({ cell, showValues = true, domain }) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [dimensions, setDimensions] = useState({ width: 0, height: 56 })

    useLayoutEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setDimensions({
                    ...dimensions,
                    width: entry.contentRect.width
                });
            }
        });
        if (containerRef.current) {
            observer.observe(containerRef.current);
        }
        return () => {
            if (containerRef.current) observer.unobserve(containerRef.current);
        };
    }, [])

    return (
        <Box ref={containerRef} style={{ width: '100%', height: dimensions.height }}>
            {dimensions.width > 0 && (
                <StackedBar
                    data={cell.getValue() as BarData[]}
                    cwidth={dimensions.width}
                    cheight={dimensions.height}
                    domain={domain}
                    showValues={showValues}
                />
            )}
        </Box>
    );
}