import React from 'react';
import { Text, Title, Image, Flex, Button, Grid, Center } from '@mantine/core';
import SearchField from '../../components/SearchField';
import MultiSearchField from '../../components/MultiSearchField';
import { GrFormNextLink } from "react-icons/gr";
import { searchMoAs as apiSearchMoa } from '../../services/moas';
import { searchIndications as apiSearchIndications } from '../../services/indication';
import classes from './Search.module.css';
import image from '../../assets/search/ie.png';
import bi_logo from '../../assets/logo/bi.svg'
import jku_logo from '../../assets/logo/jku.png'
import vds_logo from '../../assets/logo/vds.png'
import { useNavigate } from 'react-router-dom';

const Search: React.FC = () => {
  const navigate = useNavigate()
  
  const searchMoA = async (q: string) => {
    return await apiSearchMoa(q);
  }

  const searchIndication = async (q: string) => {
    return await apiSearchIndications(q);
  }

  const optionSelected = (option: string) => {
    console.log(option)
  }

  const handleNext = () => {
    navigate('/rankings')
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <Title className={classes.title}>Indication Expansion</Title>
        <Text fz="small" c="dimmed">Unlock potential <span className={classes.highlight}>indications</span> by searching for a <span className={classes.highlight}>Mode of Action (MoA)</span> and dive deep into insights and explanations for each candidate.</Text>
        <div className={classes.controls}>
          <SearchField classNames={{ root: classes.inputWrapper }} onSearch={searchMoA} onOptionSelect={optionSelected} placeholder='Search for an MoA' />
        </div>
        <div className={classes.controls}>
          <MultiSearchField classNames={{ root: classes.inputWrapper }} onSearch={searchIndication} onOptionSelect={optionSelected} placeholder='select indications (optional)' />
        </div>
        <div className={classes.controls}>
          <Button fullWidth onClick={handleNext} color="#2b2c3d" rightSection={<GrFormNextLink size={17} />}>Next</Button>
        </div>
        <Grid align='center' className={classes.logosGrid}>
          <Grid.Col span={4}><Image src={jku_logo} className={classes.logo} /></Grid.Col>
          <Grid.Col span={4}><Image src={vds_logo} className={classes.logo} /></Grid.Col>
          <Grid.Col span={4}><Image src={bi_logo} className={classes.logo} /></Grid.Col>
        </Grid>
      </div>
      <Image radius="md" src={image} className={classes.main_image} />
    </div>
  );
}

export default Search;
