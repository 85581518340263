import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Search  from './pages/Search';
import Evidence  from './pages/Evidence';
import Example  from './pages/Rankings';


export function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Search />} />
        <Route path="/evidence" element={<Evidence />} />
        <Route path="/rankings" element={<Example />} />
      </Routes>
    </Router>
  );
}
