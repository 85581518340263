import React, { useState } from "react";
import { DataStreamType } from "../../types";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table"
import { Stack, Group, Image, AppShell, Badge, Grid, ColorSwatch, Text, Button } from "@mantine/core"
import indications from "../../assets/data/indications.json"
import { BarChartHeader, StackedBarCell } from "../../components/Rankings/StackedBarCell";
import bi_logo from '../../assets/logo/bi.svg'
import jku_logo from '../../assets/logo/jku-dark.png'
import vds_logo from '../../assets/logo/vds-dark.png'
import { IconArrowLeft, IconArrowRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import * as d3 from 'd3'

const hdata = [
    { name: DataStreamType.TxtMiningOverall, value: 0.35, color: '#0e7c7b' },
    { name: DataStreamType.TxtMiningNovelty, value: 0.05, color: '#605C8B' },
    { name: DataStreamType.DiffExpr, value: 0.1, color: '#feb95f' },
    { name: DataStreamType.OTP, value: 0.2, color: '#d11149' },
    { name: DataStreamType.OTG, value: 0.3, color: '#715b64' }
]

const rows = indications
const domain: [number, number] = [
    d3.min(indications.map(d => d.detailed_scores), d => d3.min(d, dd => dd.value)),
    d3.max(indications.map(d => d.m_score), d => d3.max(d, dd => dd.value))
]

const Rankings = () => {
    const navigate = useNavigate()
    const [hoveredRowId, setHoveredRowId] = useState(null);

    const columns = [
        {
            accessorKey: "indication.name",
            header: "Indication",
            size: 100,
            enableSorting: false,
            enableColumnFilter: true,
            enableColumnActions: true
        },
        {
            accessorKey: "m_score",
            header: "M_score",
            Cell: ({ cell }) => <StackedBarCell cell={cell} showValues={true} domain={domain} />,
            enableFilters: false,
            mantineTableBodyCellProps: {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    border: '1px solid #ccc'
                }
            },
            enableSorting: true,
            enableColumnFilter: false,
            enableColumnActions: true
        },
        {
            accessorKey: "detailed_scores",
            header: "Detailed score",
            Header: (column) => <BarChartHeader data={hdata} height={80} />,
            Cell: ({ cell }) => <StackedBarCell cell={cell} showValues={cell.row.id === hoveredRowId} domain={domain} />,
            enableFilters: false,
            mantineTableBodyCellProps: {
                style: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    border: '1px solid #ccc'
                }
            },
            enableSorting: false,
            enableColumnFilter: false,
            enableColumnActions: false
        },
    ]

    const table = useMantineReactTable({
        columns, data: rows,
        enableRowSelection: true,
        enableSelectAll: false,
        enableColumnResizing: false,
        enableDensityToggle: false,
        mantineSelectCheckboxProps: {
            size: 'xs',
            wrapperProps: {
                width: '10px'
            }
        },
        mantineTableProps: {
            style: {
                tableLayout: "fixed"
            }
        },
        enableGlobalFilter: false,
        initialState: {
            showColumnFilters: true,
            density: 'xs',
            pagination: {
                pageIndex: 0,
                pageSize: 30
            }
        },
        mantineTableBodyRowProps: ({ row }) => ({
            onMouseEnter: () => { setHoveredRowId(row.id) },
            onMouseLeave: () => { setHoveredRowId(null) },
        }),
        enableHiding: false,
        enableStickyHeader: true,
        renderTopToolbar: false,
        mantineBottomToolbarProps: {
            style: {
                marginBottom: "50px",
                padding: "5px"
            }
        }
    })

    const handleNext = () => {
        navigate('/evidence')
    }

    const handlePrev = () => {
        navigate('/')
    }

    return (
        <AppShell header={{ height: 60 }}>
            <AppShell.Header style={{ backgroundColor: "#34354a", paddingRight: "5px", paddingLeft: "5px" }}>
                <Grid>
                    <Grid.Col span={6}>
                        <Group justify="flex-start" style={{ marginTop: "12px" }}>
                            <Badge size="xl" color="#d8b1ea" variant="outline" styles={{ root: { textTransform: 'none' } }}>anti-IL1RL2</Badge>
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <Group justify="flex-end">
                            <Image src={jku_logo} style={{ maxHeight: "100%", maxWidth: "100px" }} />
                            <Image src={vds_logo} style={{ maxHeight: "100%", maxWidth: "100px" }} />
                            <Image src={bi_logo} style={{ maxHeight: "100%", maxWidth: "100px" }} />
                        </Group>
                    </Grid.Col>
                </Grid>
            </AppShell.Header>
            <AppShell.Main>
                <Stack gap={0}>
                    <Group justify="center" style={{ padding: "1%" }}>
                        <Group>
                            <ColorSwatch color="#0e7c7b"/>
                            <Text>Text Mining Overall</Text>
                        </Group>
                        <Group>
                            <ColorSwatch color="#605C8B" />
                            <Text>Text Mining Novelty</Text>
                        </Group>
                        <Group>
                            <ColorSwatch color="#feb95f"/>
                            <Text>Differential Expression</Text>
                        </Group>
                        <Group>
                            <ColorSwatch color="#d11149"/>
                            <Text>Open Targets Platform</Text>
                        </Group>
                        <Group>
                            <ColorSwatch color="#715b64"/>
                            <Text>Open Targets Genetics</Text>
                        </Group>
                    </Group>
                    <div style={{ width: '100%' }}>
                        <MantineReactTable table={table} />
                    </div>
                </Stack>
            </AppShell.Main>
            <AppShell.Footer style={{ backgroundColor: "#666980", padding: "5px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button onClick={handlePrev} variant="filled" color="#01010a" leftSection={<IconArrowLeft size={14} />} style={{ marginRight: "5px" }}>Search</Button>
                <Button onClick={handleNext} variant="filled" color="#01010a" rightSection={<IconArrowRight size={14} />}>Explain</Button>
            </AppShell.Footer>
        </AppShell >
    );
}

export default Rankings;
